/* Fonts */

/* Paragraphes */
@font-face {
  font-family: PT Sans;
  src: url(../assets/fonts/PTSans-Regular.ttf);
}

@font-face {
  font-family: PT Sans;
  src: url(../assets/fonts/PTSans-Bold.ttf);
  font-weight: 600;
}

/* Titles */
@font-face {
  font-family: Mulish;
  src: url(../assets/fonts/Mulish-Regular.ttf);
}

@font-face {
  font-family: Mulish;
  src: url(../assets/fonts/Mulish-Bold.ttf);
  font-weight: 600;
}

/* Colors */

$bg: #16161D;
$dark-bg: #0c0c0f;
$main-color: #FBDB48;
$black: #070707;
$white: #fff;
$light-grey: #ddd;
$grey: #797979;

/* Mixins */

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-column-nowrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

@mixin flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}

@mixin button {
  background-color: $main-color;
  outline: 0;
  cursor: pointer;
  font-family: PT Sans;
  font-size: 1.6rem;
  color: $bg;
  padding: 0.5rem 4rem 0.75rem;
  border-radius: 9999px;
  border: solid 1px $main-color;
  transition: color .2s, background-color .2s, border-color .2s;

  &:hover {
    background: $bg;
    color: $main-color;

  }
}

@mixin profile-picture {
  img {
    display: block;
    width: 6rem;
    height: 6rem;
    border-radius: 9999px;
    object-fit: cover;
    margin-top: 3rem;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}
@mixin mobile-landscape{
  @media only screen and (max-width: 812px) and (orientation: landscape) {
    @content;
  }
}

@mixin dangerous-height{
  @media only screen and (max-height: 505px) {
    @content;
  }
}


/* General */
#root {
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  width: 100%;

  body {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: center;
    font-size: 1.6rem;
    background-color: $bg;
    color: $white;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: PT sans;

    #root {
      height: 100%;
      width: 100%;
    }

    * {
      box-sizing: border-box;
      font-size: 1.6rem;
    }

    button {
      font-family: PT sans;
    }

    h1 {
      font-family: Mulish;
      font-size: 5rem;
      text-transform: uppercase;
    }

    h2 {
      font-size: 2rem;
    }

    strong {
      color: $main-color;
      font-weight: bold;
      font-size: inherit;
    }

    svg {
      pointer-events: none;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    textarea {
      resize: none;
      font-size: 1.6rem;
      font-family: PT sans;
    }

    input {
      font-family: PT sans;
    }

    .transition-screen{
      background-color:$bg;
      position:fixed;
      z-index:5;
      height:100vh;
      width:100vw;
      top:0;
      left:0;
      transition:left .5s;
      padding:2rem;
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p{
        font-size:2rem;
      }

      &.hidden{
        &.left{
          left:-100vw;
        }
        &.right{
          left:100vw;
        }
      }
    }
  }
}