html {
  .contact {
    padding: 2rem;
    height: calc(100vh - env(safe-area-inset-bottom));

    #root {
      @include landscape {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      header {
        @include flex-wrap;
        justify-content: center;
        align-items: center;

        .back-button {
          border: 0;
          outline: 0;
          background: transparent;
          cursor: pointer;
          transition:opacity .2s;

          img {
            height: 2rem;
            margin-right: 2rem;
          }

          &:hover{
            opacity: .7;
          }
        }

        h1 {
          font-size: 2.3rem;
        }
      }

      main {
        @include flex-column-wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        .small-picture {
          @include profile-picture;

          img {
            @include landscape {
              width: 10.7rem;
              height: 10.7rem;
            }
          }

        }

        >button {
          background: transparent;
          outline: 0;
          border: 0;
          color: $white;
          opacity: .4;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.5rem;
          transition:opacity .2s;
          cursor:pointer;

          &.hidden {
            display: none;
          }

          p {
            font-size: 1.4rem;
          }

          img {
            height: 1.4rem;
            margin-right: 0.5rem;
          }

          &:hover{
            opacity:.3;
          }
        }

        .chat-messages {
          width: 100%;
          @include flex-column-nowrap;
          height: calc(50vh - 6rem);
          margin: 3rem 0;
          overflow-y: auto;
          scroll-behavior: smooth;

          @include landscape {
            padding-right: 1rem;

            &::-webkit-scrollbar {
              height: 10px;
              width: 10px;
            }

            &::-webkit-scrollbar-track {
              border-radius: 5px;
              background-color: $bg;
            }

            &::-webkit-scrollbar-track:hover {
              background-color: $bg;
            }

            &::-webkit-scrollbar-track:active {
              background-color: $bg;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background-color: $dark-bg;
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color: $dark-bg;
            }

            &::-webkit-scrollbar-thumb:active {
              background-color: $black;
            }
          }

          li {
            background: linear-gradient(180deg, $black, #0a0a0a);
            max-width: 85%;
            width: max-content;
            border-radius: 1.5rem;
            border-bottom-left-radius: 0;
            padding: 1rem 2.5rem;
            display: flex;
            margin: 0.75rem 0;
            position: relative;
            top: 2rem;
            opacity: 0;
            transition: top .5s, opacity .5s;

            &.displayed {
              top: 0;
              opacity: 1;
            }

            &.from-user {
              align-self: flex-end;
              border-bottom-left-radius: 1.5rem;
              border-bottom-right-radius: 0;
            }

            &.messages-end {
              background: transparent;
              opacity: 0 !important;
            }
          }
        }

        .chat-input {
          @include flex-wrap;
          justify-content: center;
          align-items: center;
          width: 100%;


          &.hidden {
            display: none;
          }


          li {
            text-align: center;

            button {
              @include button;
              margin: .5rem;

              &.unavailable {
                opacity: .3;
                pointer-events: none;
              }
            }

            textarea,
            input {
              background-color: $black;
              width: 100%;
              border: 0;
              outline: 0;
              color: white;
              border-radius: 1.5rem;
              padding: 1rem 2.5rem;
            }
          }
        }

        .end-ctas {
          li {

            button,
            .button-like {
              @include button();
              text-decoration: none;
              margin: 1rem auto;
              display: block;
              text-align: center;
              width: max-content;
              transition: transform .3s, opacity .3s;
              transform: scale(1);
              opacity: 1;

              &.hidden {
                transform: scale(.5);
                opacity: 0;
              }

            }
          }
        }
      }

    }
  }
}