@import "../settings";

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes floating {
  from {
    transform: translate(0, 0);
  }

  65% {
    transform: translate(0, -0.5rem);
  }

  to {
    transform: translate(0, -0px);
  }
}

@keyframes floating-reverse {
  from {
    transform: translate(0, -0.5rem);
  }

  65% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -0.5rem);
  }
}

@keyframes ft0 {
  from {
    transform: translate(0, 0);
  }

  0% {
    transform: translate(0, 0);
  }

  2% {
    transform: translate(.2rem, 0);
  }

  4% {
    transform: translate(0, 0);
  }

  6% {
    transform: translate(.2rem, 0);
  }

  8% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 0);
  }

  ;

}

@keyframes ft1 {
  from {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(0, 0);
  }

  27% {
    transform: translate(.2rem, 0);
  }

  29% {
    transform: translate(0, 0);
  }

  31% {
    transform: translate(.2rem, 0);
  }

  33% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 0);
  }

  ;

}

@keyframes ft2 {
  from {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  52% {
    transform: translate(.2rem, 0);
  }

  54% {
    transform: translate(0, 0);
  }

  56% {
    transform: translate(.2rem, 0);
  }

  58% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 0);
  }

  ;

}

@keyframes ft3 {
  from {
    transform: translate(0, 0);
  }

  75% {
    transform: translate(0, 0);
  }

  77% {
    transform: translate(.2rem, 0);
  }

  79% {
    transform: translate(0, 0);
  }

  81% {
    transform: translate(.2rem, 0);
  }

  83% {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 0);
  }

  ;

}

html {

  .home {
    //height: calc(100vh - env(safe-area-inset-bottom));
    text-align: center;

    #root {
      @include flex-column-wrap;
      justify-content: flex-start;
      align-items: center;

      button,
      .button-like {
        @include button();
        text-decoration: none;
        margin: 1rem auto;
        display: block;
        width: max-content;

      }

      .linkedin {
        color: white;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:opacity .2s;

        img {
          height: 2.2rem;
          margin-right: .8rem;
        }

        p {
          opacity: .4;
        }

        &:hover{
          opacity:.7;
        }

        @include dangerous-height{
          display:none;
        }
      }

      header {
        @include flex-column-wrap;
        justify-content: space-around;
        align-items: center;
        height: 100vh;
        width: 100vw;
        padding: 3rem 4rem;
        max-width: 1000px;

        h1 {
          span {
            display: block;
            font-size: inherit;
          }
        }

        h2 {
          margin-top: 1rem;
          font-weight: 500;
        }

        .picture {
          @include profile-picture;
          margin-bottom: 2rem;
          margin-top: 2rem;


          img {
            width: 10.7rem;
            height: 10.7rem;
            margin: 0 auto;
          }

          @include dangerous-height{
            display:none;
          }
        }

        @include dangerous-height{

          ul{
            li{
              &:nth-child(2){
                  display:none;
              }
            }
          }
        }


      }

      main {
        max-width: 1000px;
        overflow: hidden;
        width:100%;

        section {
          h2 {
            font-size: 2.3rem;
            text-transform: uppercase;
            font-family: Mulish;
            margin: 2rem 0;
          }

          &#presentation {
            padding-bottom: 2rem;

            >img {
              width: 100%;

              @include landscape {
                height: 40rem;
                object-fit: cover;
                object-position: center 0;
                max-width: 70rem;
              }
            }

            >p {
              padding: 0 2rem;
              margin: .5rem 0;
              text-align: left;

              @include landscape {
                max-width: 70rem;
                margin: .5rem auto;
              }
            }

            .button-like {
              margin: 2rem auto 6rem;
              transition: color 0.2s, background-color 0.2s, border-color 0.2s, opacity 1s, transform 1s;
            }

            .anchors-list {
              li {
                margin-bottom: 1rem;

                a {
                  text-decoration: none;
                  color: $grey;
                  border-bottom: .1rem $grey dashed;
                  padding-bottom: .3rem;
                  transition: color .2s;

                  svg {
                    height: 1.4rem;
                    margin-right: .5rem;
                    position: relative;
                    top: .2rem;

                    path {
                      stroke: $grey;
                      transition: stroke .2s;
                    }

                    &.ft0 {
                      animation: ft0 8s infinite
                    }

                    &.ft1 {
                      animation: ft1 8s infinite;
                    }

                    &.ft2 {
                      animation: ft2 8s infinite;
                    }

                    &.ft3 {
                      animation: ft3 8s infinite;
                    }
                  }

                  &:hover {
                    color: $white;

                    svg {
                      path {
                        stroke: $white;
                      }
                    }
                  }
                }
              }
            }
          }

          &:not(#presentation) {
            padding: 2rem;
          }

          &#competences {
            ul {
              @include landscape {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
              }

              li {
                border: dashed 1px $white;
                border-radius: .5rem;
                padding: 2rem;
                margin-bottom: 2rem;

                @include landscape {
                  margin: 0 1rem 2rem;
                  width: calc(40% - 2rem);
                }

                img {
                  height: 5rem;
                  margin-bottom: 1rem;

                  &.reverse {
                    transform: translate(0, -0.5rem);
                  }
                }

                p {
                  text-align: left;
                }

                &.animated {
                  img {
                    animation-name: floating;
                    animation-duration: 4s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;

                    &.reverse {
                      animation-name: floating-reverse;
                      animation-duration: 4s;
                      animation-iteration-count: infinite;
                      animation-timing-function: ease-in-out;

                      @include landscape {
                        transform: translate(0, 0);
                        animation: none;
                      }
                    }

                    @include landscape {
                      transform: translate(0, 0);
                      animation: none;
                    }
                  }
                }

                h3 {
                  font-size: 1.8rem;
                  margin-bottom: 1rem;
                }

                &:last-child {
                  margin-bottom: 2rem;
                }
              }
            }

            >p {
              margin-bottom: 1.5rem;
            }

          }

          &#prix {
            >p {
              max-width: 70rem;
              margin: .5rem auto;
              text-align: left;
            }

            .big {
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 11rem;
              background: linear-gradient(180deg, $black, #0a0a0a);
              border-radius: 1.5rem;
              border-bottom-left-radius: 0;
              padding: 1rem 2.5rem;
              width: 30rem;
              max-width: 100%;
              margin: 2rem auto;

              p {
                font-size: 1.8rem;
                color: $white;
                font-weight: bold;

                .cursor {
                  height: 1.8rem;
                  background-color: $main-color;
                  width: .6rem;
                  display: inline-block;
                  margin-left: .25rem;
                  position: relative;
                  top: .1rem;
                  animation: blink 0.5s steps(1) infinite;
                }
              }
            }

            button {
              margin-top: 2rem;
            }

          }

          &#projets {
            h3 {
              margin-bottom: 2rem;
            }

            .review {
              background-image: url(../../assets/images/la-marelle-des-saveurs.jpg);
              background-size: cover;
              background-position: center;
              margin: 0 auto 4rem;
              border-radius: .5rem;
              overflow: hidden;
              max-width: 50rem;

              article {
                background: linear-gradient(to bottom, rgba(7, 7, 7, .3), rgba(7, 7, 7, .95) 80%);
                padding: 2rem;
                width: 100%;
                height: 100%;

                >div:nth-child(1) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background-color: $main-color;
                  width: max-content;
                  margin: 0 auto 2rem;
                  color: $black;
                  opacity: .8;
                  padding: .2rem 1rem;
                  border-radius: 9999rem;

                  p {
                    margin-right: .5rem;
                  }

                  img {
                    height: 1.6rem;
                  }
                }

                .quote {
                  width: 100%;
                  color: $white;
                  margin-bottom: 2rem;
                  text-align: left;

                  h4 {
                    font-size: 1.6rem;
                    margin-bottom: .5rem;
                  }
                }

                >p {
                  text-align: left;

                  strong {
                    color: $white;
                    margin-bottom: .5rem;
                  }

                  a {
                    text-align: left;
                    width: max-content;
                    display: block;
                    color: $grey;
                    font-size: 1.5rem;
                    margin: 0 0 1rem 0;
                    text-decoration: none;
                    border-bottom: 0.1rem $grey dashed;
                    padding-bottom: 0.3rem;
                    transition: color 0.2s;

                    svg {
                      height: 1.3rem;
                      width: auto;
                      margin-right: .2rem;
                      position: relative;
                      top: .1rem;

                      path {
                        fill: $grey;
                        transition: fill .2s;
                      }
                    }

                    &:hover {
                      color: #fff;

                      svg {
                        path {
                          fill: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }

            .other-projects {
              margin-bottom: 4rem;
              display: flex;
              justify-content: center;

              li {
                /*&.zenzer {
                  button {
                    background-color: #E93776;
                    border-color: #E93776;
                  }
                }

                &.folloween {
                  button {
                    background-color: #ae0008;
                    border-color: #ae0008;
                  }
                }

                &.connivence {
                  button {
                    background-color: white;
                    border-color: white;
                  }
                }*/

                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 5rem;
                  height: 5rem;
                  margin: .5rem;
                  padding: 1rem;

                  svg {
                    height: 90%;

                    path {
                      fill: $bg;
                      transition: fill .2s;
                    }
                  }

                  &:hover {

                    svg {
                      path {
                        fill: $main-color;
                      }
                    }
                  }
                }
              }
            }

            .to-contact {
              margin-top: 1.5rem;
            }
          }

          &#mentions-legales {
            >*:not(h2) {
              margin: 1rem 0;
            }

            strong {
              color: $white;
            }

            a {
              color: $white;
              transition: color .2s, opacity 1s, transform 1s;
              display: inline-block;
              margin: 0;

              &:hover {
                color: $grey;
              }
            }

            >div {
              max-width: 40rem;
              margin: 1rem auto;
            }
          }
        }
      }

      .project-popup {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        transition: opacity 0.3s;
        z-index: 4;
        display: flex;
        overflow-y: auto;
        justify-content: center;
        align-items: center;
        padding: 4rem 0.5rem;
        opacity: 0;

        article {
          background-color: $black;
          width: 95%;
          max-width: 80rem;
          max-height: 90vh;
          padding: 2rem 2rem;
          line-height: 2.4rem;
          position: relative;
          top: 10rem;
          transition: top 0.3s;
          border-radius: .5rem;
          overflow-y: auto;

          >p {
            text-align: left;
            max-width: 50rem;
            margin: 0 auto;
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;

            .back-button {
              border: 0;
              outline: 0;
              background: transparent;
              cursor: pointer;
              margin: 0;
              padding: 0;
              width: max-content;
              height: max-content;
              transition:opacity .2s;

              img {
                height: 2rem;
                margin-right: 2rem;
                pointer-events: none;
              }
              &:hover{
                opacity:.7;
              }
            }

            h2 {
              font-size: 2.3rem;
              text-transform: uppercase;
              font-family: Mulish;
              margin: 2rem 0;
            }
          }

          .image-container {
            position: relative;
            height: 22rem;
            width: 20.5rem;
            margin: 4rem auto;
            text-align: center;

            .fade-image {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              object-fit: cover;
              opacity: 0;
              transition: opacity 1s ease-in-out;
              display: inline-block;

              &.visible {
                opacity: 1;
              }

              &.hidden {
                opacity: 0;
              }
            }
          }

          a {
            @include button;
            text-decoration: none;
            margin-top: 4rem;
            display: block;
            width: max-content;
            margin: 2rem auto;
            &:hover{
              background-color:$black;
            }
          }
        }

        &.opened {
          opacity: 1;

          article {
            top: 0;
          }
        }
      }

      footer {
        padding-bottom: 4rem;
      }
    }
  }
}